import { connect, useDispatch } from 'react-redux';
import React, {Fragment, useEffect, useState, useRef} from "react";

import swal from "sweetalert";
import {
    loadingToggleAction, identificationAction,
} from '../../../store/actions/PatientAction';
import { withRouter } from "react-router-dom";
import { Row, Col, Card, Accordion, Button, Spinner, Badge } from 'react-bootstrap'
import { getBonByCodeAction, delieverExamensAction, startLoader } from '../../../store/actions/LaboratoireActions';
import { searchDiseasesAction, 
    searchDrugsAction, addActeDetailAction, 
    updateConsultationAction, getConsultationByCodeDataAction, 
    startLoader as startConsultationLoader } from '../../../store/actions/ConsultationActions';
import {searchActesAction} from '../../../store/actions/ProviderActions';
import { currentUser } from '../../../store/selectors/CurrentUserSelector';
import { getFactureAction } from '../../../store/actions/ProviderActions';
import { calculationRembBaseAction, startLoader as startFactureLoader } from '../../../store/actions/FacturationAction';
import { DataTableBase } from '../DataTables/DataTableBase';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Button as PButton } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Button as ButtonBootstrap } from 'react-bootstrap';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { Dialog } from 'primereact/dialog';

import { DatePicker, DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import PerfectScrollbar from "react-perfect-scrollbar";
import { TabView, TabPanel } from 'primereact/tabview';
import Select from 'react-select';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
import { Tooltip } from 'primereact/tooltip';
import './LaboBonSearchForm.css';
import { STATUSES } from '../../globals';
import { useTranslation } from "react-i18next";

const LaboBonSearchForm = (props) => {
    
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [search, setSearch] = useState("");
    const [files, setFiles] = useState({});
    const [acteIds, setActesIds] = useState([]);
    const [filters, setFilters] = useState(null);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const toast = useRef(null);
    const fileUploadRef = useRef(null);
    const [examArr, setExamArr] = useState([]);
    const [consultation, setConsultation] = useState({});
    const [visible, setVisible] = useState(false);
    const [numBon, setNumBon] = useState("");
    const [fileSrc, setFileSrc] = useState(null);
    const { bon, currentUser, facture, errorMessage, successMessage, showLoading, rembCalculDatas,
        consultationData, beneficiaires, diseases, drugList, 
        actesConsult, actesByType, consultSuccessMessage, showConsultLoading } = props;

    
    const motives = [{label: t('common.motherhood'), value: 'maternity'}, {label: t('common.accident'), value: "accident"}, {label: t('common.natural-illness'), value: "sickness"}];

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            acteName: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            status: { operator: FilterOperator.OR, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
        });
        setGlobalFilterValue("");
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between">
                <PButton type="button" icon="pi pi-filter-slash" label={t('common.reinit')} outlined onClick={clearFilter} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder={t('common.search')} />
                </span>
            </div>
        );
    };

    const clearFilter = () => {
        initFilters();
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    
    const onSave = () => {

    };

    const footerContent = (
        <div>
            <PButton label="Fermer" icon="pi pi-times" onClick={() => setVisible(false)} className="p-button-text" />
        </div>
    );

    const setColumns = () => {
        return [
            {selectionMode: "multiple", style: { width: '3rem' }},
            {name: 'acteName', headerName: "Examen", filterPlaceholder: t('common.filter-exam'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: acteNameBodyTemplate},
            {name: 'prix', dataType: 'numeric', headerName: t('common.price'), style: { minWidth: '6rem' }, filter: true, bodyTemplate: null},
            {name: 'tm', dataType: 'numeric', headerName: t('common.cover'), style: { minWidth: '6rem' }, filter: true, bodyTemplate: null},
            {name: 'prixPatient', dataType: 'numeric', headerName: t('common.patient-price'), style: { minWidth: '6rem' }, filter: true, bodyTemplate: null},            
            {name: 'status', headerName: 'Statut', filterPlaceholder: t('common.status'), style: { minWidth: '12rem' }, filter: true, bodyTemplate: statusBodyTemplate, filterElement: statusFilterTemplate},
            {name: 'actions', headerName: t('common.result'), filterPlaceholder: '', style: { minWidth: '4rem'}, filter: false, bodyTemplate: getActionsTemplate},
        ]
    };

    const acteNameBodyTemplate = (rowData) => {
        return <>
            <span>{rowData.acteName}</span><br/>
            {rowData.message && 
                <>
                    <small className='text-danger'>
                        {rowData.message}
                    </small>
                </>
            }
        </>
    };

    const getActionsTemplate = (rowData) => {
        return <div style={{ width: '100%', display: 'flex', justifyContent: 'center'}}>
            {hasMenu('MENLAB') && canDo('MENLAB', 'ACEXCLABBON') && rowData.selected && <div>
                <Toast ref={toast}></Toast>

                <Tooltip target=".custom-choose-btn" content="Choisir" position="bottom" />
                <Tooltip target=".custom-cancel-btn" content="Retirer" position="bottom" />

                <FileUpload 
                    ref={fileUploadRef} 
                    name="demo[]" 
                    onSelect={e => onTemplateSelect(e, rowData.id)} 
                    onError={onTemplateClear} 
                    onClear={onTemplateClear}
                    headerTemplate={headerTemplate} 
                    itemTemplate={itemTemplate} 
                    emptyTemplate={emptyTemplate(rowData.id)}
                    chooseOptions={chooseOptions}  
                    cancelOptions={cancelOptions} />
            </div>}
        </div>
    };

    const headerTemplate = (options) => {
        const { className, chooseButton, cancelButton } = options;

        return (
            <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
                {chooseButton}
                {cancelButton}
            </div>
        );
    };

    const itemTemplate = (file) => {
        return (
            <div className="flex align-items-center flex-wrap">
                <div className="flex align-items-center" style={{ width: '40%' }}>
                    <i className='fa fa-file'></i>
                    <span className="flex flex-column text-left ml-3">
                        {file.name}
                    </span>
                </div>
                {/*<PButton type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} />*/}
            </div>
        );
    };

    const emptyTemplate = () => {
        return (
            <div className="flex align-items-center flex-column">
                <i className="pi pi-file mt-1 p-1" style={{ fontSize: '15px', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
                <span style={{ fontSize: '0.8em', color: 'var(--text-color-secondary)' }} className="my-1">
                    {t('common.add-file')}
                </span>
            </div>
        );
    };

    const chooseOptions = { icon: 'pi pi-fw pi-images', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined' };
    const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined' };

    const onTemplateSelect = (e, examId) => {
        let upFiles = e.files;
        const keys = Object.keys(upFiles);
        setExamArr(examArr.map(exam => {
            if(exam.id === examId) {
                exam.file = upFiles[keys[0]];
            }
            return exam;
        }));
    };

    /*const onTemplateRemove = (file, callback) => {
        setTotalSize(totalSize - file.size);
        callback();
    };*/

    const onTemplateClear = (examId) => {
        setExamArr(examArr.map(exam => {
            if(exam.id === examId) {
                exam.file = null;
            }
            return exam;
        }));
    };

    const statusFilterTemplate = (options) => {
        return <Dropdown value={options.value} options={props.bon?.examenBonDetail.map(facture => {
            return facture.status
        }).reduce(function (acc, curr) {
            if (!acc.includes(curr))
                acc.push(curr);
            return acc;
        }, [])} onChange={(e) => options.filterCallback(e.value, options.index)} itemTemplate={statusItemTemplate} placeholder="Choisir" className="p-column-filter" showClear />;
    };

    const statusBodyTemplate = (rowData) => {
        return <Tag value={t('statuses.'+rowData.status)} severity={getSeverity(rowData.status)} />;
    };

    const statusItemTemplate = (option) => {
        return <Tag value={option} severity={getSeverity(option)} />;
    };

    const getSeverity = (status) => {
        switch (status) {
            case STATUSES.exectuted:
                return 'success';

            case STATUSES.rejeted:
                return 'success';

            default:
                return 'warning';
        }
    };

    const searchBon = (e) => {
        e.preventDefault();
        if(search.trim().length > 0) {
            console.log(search);
            dispatch(startLoader());
            dispatch(getBonByCodeAction(search));
        }
    }

    const loadExecutedExams = () => {
        let data = {};
        /*let allKeys = Object.keys(files);
        console.log(files);
        console.log(allKeys);
        let exams = allKeys.map(key => {
            return {examenDetailId: key, examFile: files[key]}
        });*/
        let exams = examArr.filter(elt => elt.selected).map(exam => {
            return {examenDetailId: exam.id, examFile: exam.file}
        })
        data.examen = exams;
        data.executedBy = currentUser.providerId;
        data.executedName = currentUser.provider.providerName;
        console.log(data);
        
        dispatch(startLoader());
        dispatch(delieverExamensAction(data));
    }

    const isExamSelectable = (rowData) => {
        return hasMenu('MENLAB') && canDo('MENLAB', 'ACEXCLABBON') && (rowData.status === STATUSES.pending || rowData.status === STATUSES.accepted)
    }

    const loadSelectedExams = (selecExams) => {
        setExamArr(examArr.map(exam => {
            exam.selected = selecExams.includes(exam);
            return exam;
        }));
        if(selecExams.length > 0) {
            let data = {
                providerId: currentUser.providerId,
                patientId: bon.prescription.consultation.beneficiaryId,
                acteId: selecExams[0].acteId
            };
            console.log(examArr.indexOf(selecExams[0]));
            dispatch(startFactureLoader());
            dispatch(calculationRembBaseAction(data, examArr.indexOf(selecExams[0])));
        }
    }

    const loadResultFiles = (e, examDetailId, acteIdc, patientId, examKey) => {
        let upFiles = e.target.files;
        const keys = Object.keys(upFiles);
        console.log(upFiles[keys[0]]);
        let interm = {};
        interm[examDetailId] = upFiles[keys[0]];

        setFiles({...files, ...interm});

        setActesIds([...acteIds, acteIdc]);
        
        let data = {
            providerId: currentUser.providerId,
            patientId,
            acteId: acteIdc
        };
        
        dispatch(startFactureLoader());
        dispatch(calculationRembBaseAction(data, examKey));
    }
    
    const saveConsultation = (e) => {
        e.preventDefault();
        if(consultation.dossierConsultationId) {
            dispatch(startConsultationLoader());
            if(consultation.examens) {
                consultation.examens.forEach(exam => {
                    let data = {
                        examenBonId: consultation.examenBonId ? consultation.examenBonId : -1,
                        consultationId: consultation.dossierConsultationId,
                        acteId: exam.id,
                        acteName: exam.nameFr,
                        acteCode: exam.code,
                        //facturePrestataireTMPId: consultation.facturePrestataireTMPId
                    };
                    dispatch(addActeDetailAction('examens', data, numBon));
                });
            }
        }else{
            let data = {
                bonId: consultation.bonId,
                //facturePrestataireTMPId: consultation.facturePrestataireTMPId ? consultation.facturePrestataireTMPId : id,
                diagnostics: {
                    final: true,
                    diagnostic: consultation.diagnostic
                },
                questions: {
                    motif: consultation.motif,
                    allergies: [],
                    habits: [],
                    persAnts: [],
                    famAnts: [],
                    identities: [],
                    inspections: [],
                    palpations: [],
                    oscultations: [],
                    percussions: [],
                    observations: "",
                },
                optique: consultation.optique ? consultation.optique : {},
                fileSrc
            }
            if(consultation.examens) {
                data = {
                    ...data,
                    examens: consultation.examens.map(exam => {
                        let res = exam;
                        delete res['label'];
                        delete res['value'];
                        return res;
                    })
                }
            }
            dispatch(startConsultationLoader());
            dispatch(updateConsultationAction(data, numBon));
        }
    }

    const noChange = () => {
        return (!consultation.examens || consultation.examens.length === 0) && !consultation.diagnostic && !fileSrc;
    }

    const addOrdonance = (e) => {
        let upFiles = e.target.files;
        const keys = Object.keys(upFiles);
        console.log(upFiles[keys[0]]);
        setFileSrc(upFiles[keys[0]]);
    }

    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if(!theMenu){
          return false;
        }else{
          return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
      }
    
     const hasMenu = (menuCode) => {
          return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
      }

    useEffect(() => {
        if(hasMenu('MENLAB') && canDo('MENLAB', 'ACVIEWLABBON')){
            console.log("About to call all the consultations");
        }else{
            props.history.push('/access-denied');
        }
    }, []);

    useEffect(() => {
        setExamArr(bon.examenBonDetail ? bon.examenBonDetail.map(exam => {
            exam.file = null;
            exam.prix = null;
            exam.prixPatient = null;
            exam.tm = null;
            exam.selected = false;
            exam.message = null;
            return exam;
        }) : []);
    }, [bon]);

    useEffect(() => {
        console.log(rembCalculDatas);
        setExamArr(examArr.map((exam, i) => {
            exam.prix = !rembCalculDatas.filter(fact => {return fact.index === i})[0]?.data.montantPolice ?
            rembCalculDatas.filter(fact => {return fact.index === i})[0]?.data.montantConvention :
            Math.min(rembCalculDatas.filter(fact => {return fact.index === i})[0]?.data.montantConvention, rembCalculDatas.filter(fact => {return fact.index === i})[0]?.data.montantPolice);
            exam.prixPatient = rembCalculDatas.filter(fact => {return fact.index === i})[0]?.data.montantPaye;
            exam.tm = rembCalculDatas.filter(fact => {return fact.index === i})[0]?.data.tm + '%';
            exam.message = rembCalculDatas.filter(fact => {return fact.index === i})[0]?.data.message;
            
            return exam;
        }));
    }, [rembCalculDatas]);

    useEffect(() => {
        if(search.trim().length > 0) {
            console.log("checking things here");
            dispatch(startLoader());
            dispatch(getBonByCodeAction(search));
        }
    }, [successMessage, errorMessage]);

    useEffect(() => {
        if(consultSuccessMessage === 'Consultation Updated Successfully Completed' || consultSuccessMessage === 'Add acte Successfully Completed') {
            setVisible(false);
            dispatch(startLoader());
            dispatch(getBonByCodeAction(numBon));
        }
    }, [consultSuccessMessage]);

    useEffect(() => {
        if(visible && numBon) {
            dispatch(startConsultationLoader());
            dispatch(getConsultationByCodeDataAction(numBon));
        }
    }, [visible]);

    useEffect(() => {
        if(props.consultationData) {
            setConsultation({
                bonId: props.consultationData.id,
                facturePrestataireTMPId: props.consultationData.facturePrestataireTMPId,
                beneficiaryId: props.consultationData.beneficiaryId,
                beneficiaryName: props.consultationData.beneficiaryName,
                doctorId: props.consultationData.doctorId,
                doctorName: props.consultationData.doctorName,
                acteId: props.consultationData.acteId,
                acteName: props.consultationData.acteName,
                acteCode: props.consultationData.acteCode,
                date: props.consultationData.date,
                motif: props.consultationData.motif,
                dossierConsultationId: props.consultationData.dossierConsultation?.id,
                examenBonId: props.consultationData.prescription?.pharmacieBon[0]?.id,
                examArr: props.consultationData.prescription?.examensBon[0]?.examenBonDetail?.map(elt => {
                    elt.selected = false;
                    return elt;
                }),
            })
        }
        //dispatch(getPrestationAction(id));
    }, [props.consultationData]);

    return (
        <Fragment>
            <div className="bg-white p-5">
                <div className='row justify-content-center mt-4'>
                    <form onSubmit={(e) => searchBon(e)} className="d-flex">
                        <input type="search" placeholder='Code du Bon' required value={search} onChange={e => setSearch(e.target.value)} className='form-control input-default mb-2' /> 
                        
                        { showLoading && 
                            <div className='col-6'>
                                <Button variant="primary" disabled>
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />{' '}
                                    <span className="visually-hidden">{t('common.await-text')}</span>
                                </Button> 
                            </div>}
                        { !showLoading &&
                            <div className='col-4'>
                                <button type='submit' className='btn btn-primary btn-block'>
                                    <i className='la la-search'></i>
                                </button>
                            </div> }
                    </form>
                </div>
                <div className='row mt-5 justify-content-center align-items-center h500'>
                    {Object.keys(bon).length === 0 && showLoading && <small>{t('laboratory.await-text-ordonance')}</small>}
                    {Object.keys(bon).length === 0 && !showLoading && <small>{t('laboratory.no-prescription.found')}</small>}
                    {Object.keys(bon).length > 0 &&
                        <div className='col'>
                            <h4 className='mt-2 text-dark text-start'>
                                <span style={{textDecoration: 'underline'}}>Patient:</span>
                                <span className='text-bold text-primary ml-2'>{bon.prescription?.consultation?.beneficiaryName}</span>
                            </h4>
                            <h4 className='mt-2 text-dark text-start'>
                                <span style={{textDecoration: 'underline'}}>{t('laboratory.prescriber')}:</span>
                                <span className='text-bold text-primary ml-2'>{bon.prescription.consultation.doctorName}</span>
                            </h4>
                            <div className="border-top my-2"></div>
                            <div className='row justify-content-between my-5 mx-2'>
                                <span>{t('laboratory.total-amount')}: <b className='text-primary'>{
                                    examArr.reduce((sum, exam) => {
                                        return exam.selected ? sum + exam.prix : 0
                                    }, 0)
                                }</b></span>
                                <span>{t('laboratory.patient-amount-label')}: <b className='text-primary'>{
                                    examArr.reduce((sum, exam) => {
                                        return exam.selected ? sum + exam.prixPatient : 0
                                    }, 0)
                                }</b></span>
                            </div>
                            <DataTableBase 
                                data={examArr}
                                size="small"
                                tableStyle={{ minWidth: '50rem', width: '100%' }}
                                emptyMessage="Aucun examen trouvé" 
                                filters={filters}
                                globalFiltersFields={['acteName', 'status']}
                                header={renderHeader()}
                                columns={setColumns()}
                                rows={10} 
                                loading={props.showLoading || props.showFactureLoading}
                                rowClick={true}
                                selectedData={examArr.filter(exam => exam.selected)}
                                setSelectedDatas={(val) => loadSelectedExams(val)}
                                isRowSelectable={val => isExamSelectable(val)}
                            />

                            
                            <div className='row p-4 justify-content-between'>
                                {hasMenu('MENLAB') && canDo('MENLAB', 'ACEXCLABBON') && <div className='row mt-2 justify-content-end'>
                                    { showLoading && 
                                        <Button variant="primary" disabled>
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />{' '}
                                            <span className="visually-hidden">{t('common.await-text')}</span>
                                        </Button> }
                                    { !showLoading &&
                                        <Button
                                            as='a'
                                            variant='primary light'
                                            href='#'
                                            className='btn-card'
                                            disabled={!examArr.find(elt => elt.selected) /*|| examArr.find(elt => elt.selected && !elt.file)*/}
                                            onClick={() => {
                                                if(examArr.reduce((sum, exam) => {
                                                    return exam.selected ? sum + exam.prixPatient : 0
                                                }, 0) > 0) {
                                                    swal({
                                                        title: "Confirmation de paiement",
                                                        text:"Confirmez-vous que le patient "+
                                                            bon.prescription?.consultation?.beneficiaryName
                                                            +" a bien payé la somme de "+
                                                            examArr.reduce((sum, exam) => {
                                                                return exam.selected ? sum + exam.prixPatient : 0
                                                            }, 0)
                                                            +" due pour ces examens ?",
                                                        icon: "warning",
                                                        buttons: true,
                                                        dangerMode: true,
                                                        }).then((hasPaid) => {
                                                            if (hasPaid) {
                                                                loadExecutedExams();
                                                            }
                                                    })
                                                }else{
                                                    loadExecutedExams();
                                                }
                                            }}
                                            >
                                            Valider
                                        </Button> }
                                </div>}
                                <div className='row justify-content-end my-4'>
                                    <Button
                                        as='a'
                                        href='#'
                                        onClick={() => setVisible(true)}
                                        variant='info light'
                                        className='btn-card btn-info'
                                    >
                                        + Compléter la prescription
                                    </Button>
                                </div>

                                <Dialog header="Compléter la prescription" visible={visible} maximizable style={{ width: '50vw'}} onHide={() => setVisible(false)} footer={footerContent}>
                                    <form onSubmit={onSave}>
                                        <div className='row my-2 align-items-center'>
                                            <div className="col-4">
                                                <div className="form-group">
                                                    <label className='mb-1 '>
                                                        <strong>{t('common.code')} du bon</strong>
                                                        <span className='text-danger ml-1'>*</span>
                                                    </label>
                                                    <input
                                                        value={numBon}
                                                        type='text'
                                                        width={"50px"}
                                                        className='form-control input-group-prepend'
                                                        onChange={e => {
                                                            setNumBon(e.target.value);
                                                        }}
                                                        disabled={props.showConsultLoading}
                                                        onKeyDown={(e) => {
                                                            if(e.key === "Enter") {
                                                                e.preventDefault();
                                                                dispatch(startConsultationLoader());
                                                                dispatch(getConsultationByCodeDataAction(numBon));
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            {props.showConsultLoading && <div className="col-1">
                                                <ProgressSpinner style={{width: '25px', height: '25'}} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                                            </div>}
                                            <div className={props.showConsultLoading ? "col-7" : "col-8"}>
                                                <div className="form-group">
                                                    <label className='mb-1 '>
                                                        <strong>Assuré</strong>
                                                        <span className='text-danger ml-1'>*</span>
                                                    </label>
                                                    <input
                                                        value={consultation.beneficiaryId+" - "+consultation.beneficiaryName}
                                                        type='text'
                                                        width={"50px"}
                                                        className='form-control input-group-prepend'
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <TabView>

                                            <TabPanel header="Consultation">
                                                <div className='px-1 pt-1'>
                                                    <div className='row align-items-center'>
                                                        <div className="col-3">
                                                            <div className="form-group">
                                                                <label className='mb-1 '>
                                                                    <strong>{t('client.type')}</strong>
                                                                    <span className='text-danger ml-1'>*</span>
                                                                </label>
                                                                <Select
                                                                    className="select-search"
                                                                    value={{label: consultation.acteName, value: consultation.acteId, code: consultation.acteCode}}
                                                                    options={props.actesConsultation?.map(act => {
                                                                        return {label: act.nameFr, value: act.id, code: act.code}
                                                                    })}
                                                                    name="Type"
                                                                    placeholder="Choisir un type"
                                                                    search
                                                                    isRequired
                                                                    isDisabled={true}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-3">
                                                            <div className="form-group">
                                                                <label className='mb-1 '>
                                                                    <strong>{t('common.doctor')}</strong>
                                                                    <span className='text-danger ml-1'>*</span>
                                                                </label>
                                                                <Select
                                                                    className="select-search"
                                                                    value={{label: consultation.doctorName, value: consultation.doctorId}}
                                                                    options={props.provider?.providerUsers?.map(provUser => {
                                                                        return {label: provUser.user.username, value: provUser.userId}
                                                                    })}
                                                                    name="Doctor"
                                                                    placeholder="Choisir un médecin"
                                                                    disabled={props.showLoading}
                                                                    search
                                                                    isRequired
                                                                    isDisabled={true}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-3">
                                                            <div className="form-group">
                                                                <label className='mb-1 '>
                                                                    <strong>{t('common.hourdate')}</strong>
                                                                    <span className='text-danger ml-1'>*</span>
                                                                </label>
                                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                    <DateTimePicker
                                                                        autoOk
                                                                        label=""
                                                                        clearable
                                                                        value={consultation.date}
                                                                        format="dd/MM/yyyy"
                                                                        required
                                                                        disabled
                                                                    />
                                                                </MuiPickersUtilsProvider>
                                                            </div>
                                                        </div>
                                                        <div className="col-3">
                                                            <div className="form-group">
                                                                <label className='mb-1 '>
                                                                    <strong>{t('appointment.reason')}</strong>
                                                                    <span className='text-danger ml-1'>*</span>
                                                                </label>
                                                                <Select
                                                                    className="select-search"
                                                                    options={motives}
                                                                    value={motives.filter(elt => elt.value === consultation.motif).map(res => {return {label: res.label, value: res.value}})}
                                                                    name="Type"
                                                                    placeholder="Choisir un type"
                                                                    isDisabled={true}
                                                                    search
                                                                    isRequired
                                                                />
                                                            </div>
                                                        </div>
                                                        {!consultation.dossierConsultationId && <div className='col-12'>
                                                            <div className='form-group'>
                                                                <label className='mb-1 '>
                                                                    <strong>{t('common.diagnose')}</strong>
                                                                    <span className='text-danger ml-1'>*</span>
                                                                </label>
                                                                <Select
                                                                    className="select-search"
                                                                    options={props.diseases?.map(elt => {return {label: elt.name, value: elt.code}})}
                                                                    placeholder="Saisir un mot clé pour trouver l'affection"
                                                                    search
                                                                    isMulti
                                                                    onInputChange={(val) => {
                                                                        console.log(val);
                                                                        if(val.length >= 1){
                                                                            dispatch(searchDiseasesAction(val));
                                                                        }
                                                                    }}
                                                                    isDisabled={props.showConsultLoading}
                                                                    onChange={(vals) => {
                                                                        setConsultation({...consultation, diagnostic: vals ? vals.map(val => {
                                                                            return {code: val.value, name: val.label}
                                                                        }) : []});
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>}
                                                    </div>

                                                    <h5 className='mt-4 text-primary'>{t('common.exam')}</h5>
                                                    <div className="border-top mt-2 mb-4"></div>
                                                    <div className='row justify-content-between align-items-center'>
                                                        <div className='col-12'>
                                                            <div className='form-group'>
                                                                <label className='mb-1 '>
                                                                    <strong>{t('common.search-exam')}</strong>
                                                                </label>
                                                                <Select
                                                                    className="select-search"
                                                                    options={props.actesConsult?.map(elt => {return {...elt, label: elt.nameFr+(elt.isAccord ? '(Accord Préalable)' : ''), value: elt.code}})}
                                                                    placeholder="Saisir un mot clé pour trouver l'examen"
                                                                    search
                                                                    isMulti
                                                                    isDisabled={props.showConsultLoading}
                                                                    onInputChange={(val) => {
                                                                        console.log(val);
                                                                        if(val.length >= 1){
                                                                            dispatch(searchActesAction(val, props.consultationData?.providerId, 'Examen'));
                                                                        }
                                                                    }}
                                                                    onChange={(vals) => {
                                                                        setConsultation({...consultation, examens: vals ? vals.map(val => {
                                                                            return val;
                                                                        }) : []});
                                                                    }}
                                                                />
                                                            </div>

                                                            <div className="col-12 mb-2">
                                                                <div className='form-group'>
                                                                    <label className='mb-1 '>
                                                                        <strong>Joindre l'ordonance</strong>
                                                                    </label>
                                                                    <div>
                                                                        <div className='input-group'>
                                                                            <div className='custom-file'>
                                                                                <input type="file" required className='custom-file-input' onChange={(e) => addOrdonance(e)} />
                                                                                <label className='custom-file-label'>{fileSrc ? <small>{fileSrc.name}</small> : 'Choisir'}</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='row justify-content-end px-3 align-items-center'>
                                                        { props.showConsultLoading && 
                                                            <Button variant="primary" disabled>
                                                                <Spinner
                                                                    as="span"
                                                                    animation="grow"
                                                                    size="sm"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                />{' '}
                                                                <span className="visually-hidden">{t('common.await-text')}</span>
                                                            </Button> }
                                                        { !props.showConsultLoading && 
                                                            <button className='btn btn-primary' disabled={noChange()} onClick={e => saveConsultation(e)}>Enregistrer</button>
                                                        }
                                                    </div>
                                                    
                                                </div>
                                            </TabPanel>

                                        </TabView>
                                    </form>
                                </Dialog>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </Fragment>
    )
};

const mapStateToProps = (state) => {
    return {
       bon: state.laboratoire.bon,
       consultationData: state.consultation.consultationByCode,
       beneficiaires: state.contrat.beneficiaires,
       diseases: state.consultation.diseases,
       drugList: state.consultation.drugs,
       actesConsult: state.provider.actes,
       actesByType: state.consultation.actesByType,
       consultSuccessMessage: state.consultation.successMessage,
       successMessage: state.laboratoire.successMessage,
       errorMessage: state.laboratoire.errorMessage,
       showLoading: state.laboratoire.showLoading,
       facture: state.provider.facture,
       rembCalculDatas: state.facturation.rembCalculDatas,
       showFactureLoading: state.facturation.showLoading,
       showConsultLoading: state.consultation.showLoading,
       currentUser: currentUser(state)
    };
};
export default withRouter(connect(mapStateToProps)(LaboBonSearchForm));