import React, { useEffect, useRef, Fragment } from "react";

import { connect, useDispatch } from 'react-redux';
import { useParams, withRouter } from "react-router-dom";
import { getFactureAction, startLoader } from "../../../store/actions/FacturationAction";
import { currentUser } from "../../../store/selectors/CurrentUserSelector";
import {format} from 'date-fns';
import { useReactToPrint } from 'react-to-print';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { TriStateCheckbox } from 'primereact/tristatecheckbox';
import { DataTableBase } from "../DataTables/DataTableBase";
import { TreeTableBase } from "../DataTables/TreeTableBase";
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import { STATUSES } from '../../globals';
import { useTranslation } from "react-i18next";
class FactureTemplate extends React.Component {

    constructor(props) {
        super(props);
    }

    statusColor = () => {
        return "text-secondary"
    };

    getTableColumns = () => {
        return [
            {field: 'intitule', header: 'Intitulé', expander: true, headerClassName: 'w-4 text-xs'},
            {field: 'montant', header: 'T.P', expander: false, headerClassName: 'text-xs'},
            {field: 'quantite', header: 'Qté', expander: false, headerClassName: 'text-xs'},
            {field: 'montantTotal', header: 'Total', expander: false, headerClassName: 'text-xs'},
            {field: 'montantPatient', header: 'Part Assuré', expander: false, headerClassName: 'text-xs'},
            {field: 'montantAssureur', header: 'Part Assureur', expander: false, headerClassName: 'text-xs'},
            {field: 'date', header: 'Date', expander: false, headerClassName: 'text-xs'},
            {field: 'montantRejete', header: 'M.R', expander: false, headerClassName: 'text-xs'},
            {field: 'motifRejet', header: 'Mo.R', expander: false, headerClassName: 'text-xs'},
        ]
    }

    rowClassName = (node) => {
        return { 'p-highlight': (node.key.split('').includes('é')),
                 'bg-red-300 text-white': (node.data.motifRejet),
                 'text-sm': true, 
                 'text-xs': (node.key.split('').includes('h')),
                 'font-bold': (!node.key.split('').includes('h')),
                 'p-0': true,
                };
    }

    setDatas = (arr) => {
        let res = arr?.map((elt, i) => {
            let levOne = {
                key: 'Assuré'+i,
                data: {
                    intitule: elt.patientName,
                    montant: elt.totalBaseRemboursement,
                    quantite: '-',
                    montantTotal: elt.totalAmount,
                    montantPatient: elt.totalTm,
                    montantAssureur: elt.totalAssureur,
                    date: '-',
                    montantRejete: elt.totalMontantRejecte,
                    motifRejet: ''
                },
                children: elt.factures?.map((fact, j) => {
                    return {
                        key: 'facture'+j,
                        data: {
                            intitule: 'Facture du '+fact?.dateFacturation,
                            montant: fact.totalBaseRemboursement,
                            quantite: '-',
                            montantTotal: fact.totalAmount,
                            montantPatient: fact.totalTm,
                            montantAssureur: fact.totalAssureur,
                            date: fact.dateFacturation,
                            montantRejete: fact.totalMontantRejecte,
                            motifRejet: ''
                        },
                        children: fact.factureDetails?.map((factDetail, k) => {
                            return {
                                key: 'bon'+k,
                                data: {
                                    intitule: factDetail.type,
                                    montant: factDetail.totalBaseRemboursement,
                                    quantite: '-',
                                    montantTotal: factDetail.totalAmount,
                                    montantPatient: factDetail.totalTm,
                                    montantAssureur: factDetail.totalAssureur,
                                    date: fact.dateFacturation,
                                    montantRejete: factDetail.totalMontantRejecte,
                                    motifRejet: '',
                                },
                                children: factDetail.bonDetails?.map((bon, l) => {
                                    return {
                                        key: 'acteh'+l+bon.acteId,
                                        data: {
                                            intitule: bon.acteName,
                                            montant: bon.baseRemboursement,
                                            quantite: bon.quantite,
                                            montantTotal: bon.prixTotal,
                                            montantPatient: bon.priceToPayByPatient,
                                            montantAssureur: bon.priceToPayByActiva,
                                            date: fact.dateFacturation,
                                            montantRejete: bon.montantRejete,
                                            motifRejet: bon.motifRejet
                                        },
                                        children: []
                                    }
                                })
                            }
                        })
                    }
                })
            }
            return levOne;
        }); 
        console.log(res);
        return res;    
    };

    header = () => {
        return <div className="text-xl font-bold">File Viewer</div>;
    }

    groupElements = (arr) => {
        /*let nonExclu = arr.reduce((group, product) => {
            const { patientId, patientName } = product;
            if(!product.isRejet) {
                group['['+patientId+'] - '+patientName] = group['['+patientId+'] - '+patientName] ?? [];
                group['['+patientId+'] - '+patientName].push(product);
            }
            return group;
        }, {});

        let exclus = arr.reduce((group, product) => {
            if(product.isRejet) {
                group["Exclusions"] = group["Exclusions"] ?? [];
                group["Exclusions"].push(product);
            }
            return group;
        }, {});

        return {...nonExclu, ...exclus};*/

        
    }

    totalexclusion = () => {
        return this.props.facture.facturePrestataireDetails ? this.props.facture.facturePrestataireDetails.reduce((partialSum, a) => a.isRejet ? partialSum + a.prixTotal : 0, 0) : 0;
    }

    render() {
        return (<div className="row">
                    <div className="col-lg-12">
                        <div className="card mt-3">
                        <div className="card-header">
                            {" "}
                            <strong>{this.props.facture.periode}</strong>{" "}
                            <span className="float-right">
                            <strong>Status paiement:</strong> <span className={this.statusColor()}>{this.props.t('statuses.'+this.props.facture.statusPaiement)}</span>
                            </span>{" "}
                        </div>
                        <div className="card-body">
                            {/*<div className="row mb-5">
                            <div className="mt-4 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                <h6>De:</h6>
                                <div>
                                {" "}
                                <strong>{this.props.currentUser.provider.providerName}</strong>{" "}
                                </div>
                                <div>{this.props.currentUser.username}</div>
                                <div>{this.props.currentUser.provider.city?.name}, {this.props.currentUser.provider.country?.name}</div>
                                <div>Email: {this.props.currentUser.provider.email}</div>
                                <div>Phone: +{this.props.currentUser.provider.phoneNumber}</div>
                            </div>
                            <div className="mt-4 col-xl-6 col-lg-6 col-md-6 col-sm-12 align-items-end justify-content-end">
                                <h6>À:</h6>
                                <div>
                                {" "}
                                <strong>Activa Assurance</strong>{" "}
                                </div>
                                <div>Service Paiement</div>
                                <div>Rue Prince de Galles - Douala, Cameroun</div>
                                <div>Email: paiement@group-active.com</div>
                                <div>Phone: +237692880000</div>
                            </div>
                            </div>*/}
                            <div className="table-responsive">
                                <TreeTableBase
                                    data={this.setDatas(this.props.facture.patients)}
                                    style={{ minWidth: '50rem', fontSize: '12px !important' }}
                                    columns={this.getTableColumns()}
                                    rowClassName={this.rowClassName}
                                />
                            </div>
                            <div className="row">
                            <div className="col-lg-4 col-sm-5"> </div>
                            <div className="col-lg-4 col-sm-5 ml-auto">
                                <table className="table table-clear">
                                <tbody>
                                    {/*<tr>
                                        <td className="left">
                                            <strong>Sous-total</strong>
                                        </td>
                                        <td className="right">
                                            {this.props.facture.montantTotal} 
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="left">
                                            <strong>Exclusions</strong>
                                        </td>
                                        <td className="right">{this.props.facture.facturePrestataireDetails?.reduce((partialSum, a) => partialSum + (a.isRejet ? a.prixTotal : 0), 0)}</td>
                                    </tr>*/}
                                    <tr>
                                        <td className="left">
                                            <strong>Total</strong>
                                        </td>
                                        <td className="right">
                                            <strong>{this.props.facture.montantTotal}</strong>
                                        </td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
            </div>
        );
    };

}

const FacturationDetails = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { id } = useParams();
    const {facture, currentUser} = props;
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    })

    const canDo = (menuCode, actionCode) => {
        let theMenu = props.currentUser.menus?.find(menu => menu.code === menuCode);
        if(!theMenu){
          return false;
        }else{
          return theMenu.actionMenus?.filter(action => action.code === actionCode).length > 0;
        }
      }
    
     const hasMenu = (menuCode) => {
          return props.currentUser.menus?.filter(menu => menu.code === menuCode).length > 0;
      }
    
    useEffect(() => {
        if(hasMenu('MENFACTURE') && canDo('MENFACTURE', 'ACVIEWFACT')){
            dispatch(startLoader());
            dispatch(getFactureAction(id));
        }else{
            props.history.push('/access-denied');
        }
    }, []);


    return (
        <Fragment> 
            { props.showLoading && 
            <div id="preloader">
                <div className="sk-three-bounce">
                    <div className="sk-child sk-bounce1"></div>
                    <div className="sk-child sk-bounce2"></div>
                    <div className="sk-child sk-bounce3"></div>
                </div>
            </div> }

            { !props.showLoading && 
                <>
                    <div className="form-head d-flex mb-3 mb-md-4 align-items-end">
                        <div className="mr-auto d-none d-lg-block">
                            <button
                            onClick={e => handlePrint()}
                            className="btn btn-primary btn-rounded"
                            >
                            Imprimer
                            </button>
                        </div>
                    </div>

                    <FactureTemplate facture={facture} t={t} currentUser={currentUser} ref={componentRef} />
                </>
            }
        </Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        facture: state.facturation.facture,
        showLoading: state.facturation.showLoading,
        currentUser: currentUser(state)
    };
};

export default withRouter(connect(mapStateToProps)(FacturationDetails));
