import React, { Fragment, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getProviderDataAction } from '../../../../store/actions/ProviderActions';
import { connect } from 'react-redux';
import { Link, useParams, withRouter } from "react-router-dom";
import { ListGroup } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import map from "../../../../images/svg/map.svg";
import widget8 from "../../../../images/avatar/5.png";
import task6 from "../../../../images/task/img6.jpg";
import { BankInfosModal } from "../ProviderModals/BankInfosModal";
import { Modal } from "react-bootstrap";
import { currentUser } from '../../../../store/selectors/CurrentUserSelector';
import { useTranslation } from "react-i18next";

export const ProviderView = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { id } = useParams();
    const { history, currentUser, provider } = props;
    //const provider = id ? providers.filter((prov) => {return prov.id === parseInt(id)})[0] : null;

    const [showBankModal, setShowBankModal] = useState(false);

    const canRead = () => {
        let userPres = currentUser.menus.filter(menu => menu.code === "ProfilPrestataire")[0];

        return userPres.actionMenus.filter(action => action.code === 'R').length > 0;
    }

    const canWrite = () => {
        let userPres = currentUser.menus.filter(menu => menu.code === "ProfilPrestataire")[0];

        return userPres?.actionMenus?.filter(action => action.code === 'C' || action.code === 'U').length > 0;
    }

    const manageBankModal = (val, reload) => {
        setShowBankModal(val);
        /*if(reload === true){
            window.location.reload(false);
        };*/
    }


    const tarifsData = (tarif) => {
        let res = [];
        tarif.tarifActes.forEach(act => {
            let resElt = {};
            resElt['typeActe'] = act.acte.typeActe?.nameFr;
            resElt['codeActe'] = act.acte.code;
            resElt['lettreCle'] = act.acte.lettreCle.code + ' * ' + act.acte.coefLettre;
            resElt['nomActe'] = act.acte.nameFr;
            resElt['prixActe'] = act.value;
            //resElt['sousActes'] = '';
            resElt['letCleSousActes'] = '';
            let sumSubActs = 0;
            if(act.acte.sousActes.length > 0){
                act.acte.sousActes.forEach(sousAct => {
                    //resElt['sousActes'] += resElt['sousActes'].length > 0 ? ' ,' + sousAct.name : sousAct.name;
                    //resElt['letCleSousActes'] += resElt['letCleSousActes'].length > 0 ? ' ,' + (sousAct.cle.code + ' * ' + sousAct.coef) : (sousAct.cle.code + ' * ' + sousAct.coef);
                    resElt['lettreCle'] += ' + ' + (sousAct.cle?.code + ' * ' + sousAct.coef);
                    sumSubActs += act.value * sousAct.coef;
                });
            }
            resElt['value'] = act.value * act.acte.coefLettre + sumSubActs;
            res.push(resElt);
        });
        return res;
    }

    useEffect(() => {
        dispatch(getProviderDataAction(id, history));
    }, []);

    return (
        <>
            <div className="page-titles">
                <ol className="breadcrumb">
                <li className="breadcrumb-item active">
                    <Link to="/provider-list">Prestataire</Link>
                </li>
                <li className="breadcrumb-item">
                    <Link to="">{provider.providerName}</Link>
                </li>
                </ol>
            </div>

            <div className="row">
                <div className="col-xl-4 col-lg-5 col-xxl-5">
                    <div className="card">
                        <div className="card-header border-0 pb-0 d-flex justyfy-content-between">
                            <h4 className="fs-20 font-w600 mb-0">
                                Specialites
                            </h4>
                        </div>
                        <div className="card-body pt-4">
                            <PerfectScrollbar
                                id="DZ_W_Todo2"
                                className="widget-media dz-scroll ps ps--active-y height370"
                            >
                                <ListGroup as="ul" variant="flush">
                                    {provider.specialites?.map((speciality, i) => (
                                    <Fragment key={i}>
                                        <ListGroup.Item as="li">{speciality.specialite.nameFr}</ListGroup.Item>
                                    </Fragment>
                                    ))}
                                </ListGroup>
                            </PerfectScrollbar>
                        </div>
                    </div>
                </div>
                <div className="col-xl-5 col-xxl-7 col-lg-7">
                    <div className="card">
                        <div className="card-body">
                            <div className="media d-sm-flex d-block text-center text-sm-left pb-4 mb-4 border-bottom">
                                <img
                                alt="widget"
                                className="rounded mr-sm-4 mr-0"
                                width={130}
                                src={provider.providerPhotoUrl ? provider.providerPhotoUrl : task6}
                                />
                                <div className="media-body align-items-center">
                                <div className="d-sm-flex d-block justify-content-between my-3 my-sm-0">
                                    <div>
                                        <h3 className="fs-22 text-black font-w600 mb-0">
                                            {provider.providerName}
                                        </h3>
                                        <p className="mb-2 mb-sm-2">
                                            Check In date 21 August 2020, 12:45 AM
                                        </p>
                                    </div>
                                    <span>#P-00016</span>
                                </div>
                                <Link
                                    to="/doctor-details"
                                    className="btn bgl-primary btn-rounded text-black mb-2 mr-2"
                                >
                                    <i className="fa fa-home mr-2 scale5 mr-2" />
                                    {provider.type?.nameFr}
                                </Link>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 mb-3">
                                    <div className="media">
                                        <span className="p-3 border border-primary-light rounded-circle mr-3">
                                            <svg
                                                width={22}
                                                height={22}
                                                viewBox="0 0 32 32"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <g clipPath="url(#clip0)">
                                                <path
                                                    d="M27.5716 13.4285C27.5716 22.4285 16.0001 30.1428 16.0001 30.1428C16.0001 30.1428 4.42871 22.4285 4.42871 13.4285C4.42871 10.3596 5.64784 7.41637 7.8179 5.24631C9.98797 3.07625 12.9312 1.85712 16.0001 1.85712C19.0691 1.85712 22.0123 3.07625 24.1824 5.24631C26.3524 7.41637 27.5716 10.3596 27.5716 13.4285Z"
                                                    stroke="#2BC155"
                                                    strokeWidth={3}
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M16.0002 17.2857C18.1305 17.2857 19.8574 15.5588 19.8574 13.4286C19.8574 11.2983 18.1305 9.57141 16.0002 9.57141C13.87 9.57141 12.1431 11.2983 12.1431 13.4286C12.1431 15.5588 13.87 17.2857 16.0002 17.2857Z"
                                                    stroke="#2BC155"
                                                    strokeWidth={3}
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                </g>
                                                <defs>
                                                <clipPath id="clip0">
                                                    <rect
                                                        width="30.8571"
                                                        height="30.8571"
                                                        fill="white"
                                                        transform="translate(0.571533 0.571411)"
                                                    />
                                                </clipPath>
                                                </defs>
                                            </svg>
                                        </span>
                                        <div className="media-body">
                                            <span className="d-block text-light mb-2">
                                                Address
                                            </span>
                                            <p className="fs-18 text-dark">
                                                {provider.city?.name},{" "}
                                                <strong>{provider.country?.name}</strong>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                <div className="map-bx mb-3">
                                    <img src={map} alt="" />
                                    <Link to="/doctor-details" className="map-button">
                                        {t('provider.enlarge')}
                                    </Link>
                                    <Link className="map-marker" to="#">
                                        <i className="las la-map-marker-alt" />
                                    </Link>
                                </div>
                                </div>
                                <div className="col-lg-6 mb-lg-0 mb-3">
                                    <div className="media">
                                        <span className="p-3 border border-primary-light rounded-circle mr-3">
                                            <svg
                                                width={22}
                                                height={22}
                                                viewBox="0 0 31 31"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                d="M28.2884 21.7563V25.6138C28.2898 25.9719 28.2165 26.3264 28.073 26.6545C27.9296 26.9826 27.7191 27.2771 27.4553 27.5192C27.1914 27.7613 26.8798 27.9456 26.5406 28.0604C26.2014 28.1751 25.8419 28.2177 25.4853 28.1855C21.5285 27.7555 17.7278 26.4035 14.3885 24.238C11.2817 22.2638 8.64771 19.6297 6.67352 16.523C4.50043 13.1685 3.14808 9.34928 2.72601 5.37477C2.69388 5.0192 2.73614 4.66083 2.8501 4.32248C2.96405 3.98413 3.14721 3.67322 3.38792 3.40953C3.62862 3.14585 3.92159 2.93517 4.24817 2.79092C4.57475 2.64667 4.9278 2.57199 5.28482 2.57166H9.14232C9.76634 2.56552 10.3713 2.78649 10.8445 3.1934C11.3176 3.60031 11.6267 4.16538 11.714 4.78329C11.8768 6.01778 12.1788 7.22988 12.6141 8.39648C12.7871 8.85671 12.8245 9.35689 12.722 9.83775C12.6194 10.3186 12.3812 10.76 12.0354 11.1096L10.4024 12.7426C12.2329 15.9617 14.8983 18.6271 18.1174 20.4576L19.7504 18.8246C20.1001 18.4789 20.5414 18.2406 21.0223 18.1381C21.5031 18.0355 22.0033 18.073 22.4636 18.246C23.6302 18.6813 24.8423 18.9832 26.0767 19.1461C26.7014 19.2342 27.2718 19.5488 27.6796 20.0301C28.0874 20.5113 28.304 21.1257 28.2884 21.7563Z"
                                                stroke="#2BC155"
                                                strokeWidth={3}
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                />
                                            </svg>
                                        </span>
                                        <div className="media-body">
                                            <span className="d-block text-light mb-2">
                                                {t('common.phone')}
                                            </span>
                                            <p className="fs-18 text-dark font-w600 mb-0">
                                                {provider.phoneNumber}
                                            </p>
                                            <span className="d-block text-light mb-2">
                                                Whatsapp
                                            </span>
                                            <p className="fs-18 text-dark font-w600 mb-0">
                                                {provider.whatsappPhone}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="media">
                                        <span className="p-3 border border-primary-light rounded-circle mr-3">
                                            <svg
                                                width={22}
                                                height={22}
                                                viewBox="0 0 31 31"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                d="M5.14344 5.14331H25.7168C27.1312 5.14331 28.2884 6.30056 28.2884 7.71498V23.145C28.2884 24.5594 27.1312 25.7166 25.7168 25.7166H5.14344C3.72903 25.7166 2.57178 24.5594 2.57178 23.145V7.71498C2.57178 6.30056 3.72903 5.14331 5.14344 5.14331Z"
                                                stroke="#2BC155"
                                                strokeWidth={3}
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                />
                                                <path
                                                d="M28.2884 7.71503L15.4301 16.7159L2.57178 7.71503"
                                                stroke="#2BC155"
                                                strokeWidth={3}
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                />
                                            </svg>
                                        </span>
                                        <div className="media-body">
                                            <span className="d-block text-light mb-2">
                                                Email
                                            </span>
                                            <p className="fs-18 text-dark font-w600 mb-0">
                                            {provider.email}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6 mb-lg-0 mb-3">
                                    <div className="media">
                                        <span className="p-3 border border-primary-light rounded-circle mr-3">
                                            <svg
                                                width={22}
                                                height={22}
                                                viewBox="0 0 31 31"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                d="M28.2884 21.7563V25.6138C28.2898 25.9719 28.2165 26.3264 28.073 26.6545C27.9296 26.9826 27.7191 27.2771 27.4553 27.5192C27.1914 27.7613 26.8798 27.9456 26.5406 28.0604C26.2014 28.1751 25.8419 28.2177 25.4853 28.1855C21.5285 27.7555 17.7278 26.4035 14.3885 24.238C11.2817 22.2638 8.64771 19.6297 6.67352 16.523C4.50043 13.1685 3.14808 9.34928 2.72601 5.37477C2.69388 5.0192 2.73614 4.66083 2.8501 4.32248C2.96405 3.98413 3.14721 3.67322 3.38792 3.40953C3.62862 3.14585 3.92159 2.93517 4.24817 2.79092C4.57475 2.64667 4.9278 2.57199 5.28482 2.57166H9.14232C9.76634 2.56552 10.3713 2.78649 10.8445 3.1934C11.3176 3.60031 11.6267 4.16538 11.714 4.78329C11.8768 6.01778 12.1788 7.22988 12.6141 8.39648C12.7871 8.85671 12.8245 9.35689 12.722 9.83775C12.6194 10.3186 12.3812 10.76 12.0354 11.1096L10.4024 12.7426C12.2329 15.9617 14.8983 18.6271 18.1174 20.4576L19.7504 18.8246C20.1001 18.4789 20.5414 18.2406 21.0223 18.1381C21.5031 18.0355 22.0033 18.073 22.4636 18.246C23.6302 18.6813 24.8423 18.9832 26.0767 19.1461C26.7014 19.2342 27.2718 19.5488 27.6796 20.0301C28.0874 20.5113 28.304 21.1257 28.2884 21.7563Z"
                                                stroke="#2BC155"
                                                strokeWidth={3}
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                />
                                            </svg>
                                        </span>
                                        <div className="media-body">
                                            <span className="d-block text-light mb-2">
                                                NIU
                                            </span>
                                            <p className="fs-18 text-dark font-w600 mb-0">
                                                {provider.niu}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="media">
                                        <span className="p-3 border border-primary-light rounded-circle mr-3">
                                            <svg
                                                width={22}
                                                height={22}
                                                viewBox="0 0 31 31"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                d="M5.14344 5.14331H25.7168C27.1312 5.14331 28.2884 6.30056 28.2884 7.71498V23.145C28.2884 24.5594 27.1312 25.7166 25.7168 25.7166H5.14344C3.72903 25.7166 2.57178 24.5594 2.57178 23.145V7.71498C2.57178 6.30056 3.72903 5.14331 5.14344 5.14331Z"
                                                stroke="#2BC155"
                                                strokeWidth={3}
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                />
                                                <path
                                                d="M28.2884 7.71503L15.4301 16.7159L2.57178 7.71503"
                                                stroke="#2BC155"
                                                strokeWidth={3}
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                />
                                            </svg>
                                        </span>
                                        <div className="media-body">
                                            <span className="d-block text-light mb-2">
                                                {t('provider.date-convention')}                                        </span>
                                            <p className="fs-18 text-dark font-w600 mb-0">
                                                {provider.conventionDate}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="row">
                <div className="col-xl-4 col-xxl-4 col-lg-4">
                    <div className="card">
                        <div className="card-header border-0 pb-0 d-flex justify-content-between">
                            <h4 className="fs-20 font-w600 mb-0">
                            {t('provider.human-ressources')} 
                            </h4>
                        </div>
                        <div className="card-body pt-4">
                            <PerfectScrollbar
                                id="DZ_W_Todo2"
                                className="widget-media dz-scroll ps ps--active-y height370"
                            >
                                <ul className="timeline">
                                    {provider.providerUsers?.map(user => {
                                        return <li>
                                                    <div className="timeline-panel bgl-dark flex-wrap border-0 p-3 rounded">
                                                        <div className="media bg-transparent mr-2">
                                                            <img
                                                            className="rounded-circle"
                                                            alt="widget"
                                                            width={48}
                                                            src={widget8}
                                                            />
                                                        </div>
                                                        <div className="media-body">
                                                            <h5 className="mb-1 fs-18">{user.user.username}</h5>
                                                            <span>{user.user.userProfil[0].profil.nameFr}</span>
                                                        </div>
                                                    </div>
                                                </li>
                                    })
                                    }
                                </ul>
                            </PerfectScrollbar>
                        </div>
                    </div>
                </div>

                <div className="col-xl-4 col-xxl-4 col-lg-4">
                    <div className="card">
                        <div className="card-header border-0 pb-0 d-flex justify-content-between">
                            <h4 className="fs-20 font-w600 mb-0">
                            {t('provider.fiscal-file')} 
                            </h4>
                        </div>
                        <div className="card-body pt-4">
                            <PerfectScrollbar
                                id="DZ_W_Todo2"
                                className="widget-media dz-scroll ps ps--active-y height370"
                            >
                                <div className="basic-list-group">
                                    <ListGroup as="ul" variant="flush">
                                        <ListGroup.Item
                                            action
                                            className="flex-column"
                                        >
                                            <div className="d-flex w-100 justify-content-between">
                                                <h5 className="mb-3">
                                                    {t('provider.tax-type')}:
                                                </h5>
                                            </div>
                                            <p className="mb-1">
                                                {provider.details ? provider.details[provider.details.length - 1]?.taxType : ''}
                                            </p>
                                        </ListGroup.Item>
                                        <ListGroup.Item
                                            action
                                            className="flex-column"
                                        >
                                            <div className="d-flex w-100 justify-content-between">
                                                <h5 className="mb-3">
                                                {t('provider.tax-value')}:
                                                </h5>
                                            </div>
                                            <p className="mb-1">
                                                {provider.details ? provider.details[provider.details.length - 1]?.taxValue : ''} XAF
                                            </p>
                                        </ListGroup.Item>
                                        <ListGroup.Item
                                            action
                                            className="flex-column"
                                        >
                                            <div className="d-flex w-100 justify-content-between">
                                                <h5 className="mb-3">
                                                {t('provider.taxpayer-number')}
                                                </h5>
                                            </div>
                                            <p className="mb-1">
                                                {props.contribuable}
                                            </p>
                                        </ListGroup.Item>
                                        <ListGroup.Item
                                            action
                                            className="flex-column"
                                        >
                                            <div className="d-flex w-100 justify-content-between">
                                                <h5 className="mb-3">
                                                {t('provider.exercise-year')}
                                                </h5>
                                            </div>
                                            <p className="mb-1">
                                                {provider.details ? provider.details[provider.details.length - 1]?.exerciceYear : ''}
                                            </p>
                                        </ListGroup.Item>
                                    </ListGroup>
                                </div>
                            </PerfectScrollbar>
                        </div>
                    </div>
                </div>

                <div className="col-xl-4 col-xxl-4 col-lg-4">
                    <div className="card">
                        <div className="card-header border-0 pb-0 d-flex justify-content-between">
                            <h4 className="fs-20 font-w600 mb-0">
                                {t('provider.bank-folder')}
                            </h4>
                            {canWrite() && <button
                                onClick={() => manageBankModal(true, false)}
                                className="btn btn-outline-primary btn-rounded mb-2 mr-1"
                            >
                                + Modifier
                            </button>}
                            <Modal
                                className="fade bd-example-modal-lg"
                                show={showBankModal}
                                size="lg"
                            >
                                <BankInfosModal 
                                    showModal={showBankModal} 
                                    providerRef={provider} 
                                    manageBankModal={manageBankModal}
                                    history={history}
                                />
                            </Modal>
                        </div>
                        <div className="card-body pt-4">
                            <PerfectScrollbar
                                id="DZ_W_Todo2"
                                className="widget-media dz-scroll ps ps--active-y height370"
                            >
                                <div className="basic-list-group">
                                    <ListGroup as="ul" variant="flush">
                                        <ListGroup.Item
                                            action
                                            className="flex-column"
                                        >
                                            <div className="d-flex w-100 justify-content-between">
                                                <h5 className="mb-3">
                                                    {t('provider.bank')}:
                                                </h5>
                                            </div>
                                            <p className="mb-1">
                                                {provider.bankDetails ? provider.bankDetails[provider.bankDetails.length - 1]?.bankName : ''}
                                            </p>
                                        </ListGroup.Item>
                                        <ListGroup.Item
                                            action
                                            className="flex-column"
                                        >
                                            <div className="d-flex w-100 justify-content-between">
                                                <h5 className="mb-3">
                                                {t('provider.bank-code')}:
                                                </h5>
                                            </div>
                                            <p className="mb-1">
                                                {provider.bankDetails ? provider.bankDetails[provider.bankDetails.length - 1]?.bankCode : ''}
                                            </p>
                                        </ListGroup.Item>
                                        <ListGroup.Item
                                            action
                                            className="flex-column"
                                        >
                                            <div className="d-flex w-100 justify-content-between">
                                                <h5 className="mb-3">
                                                {t('provider.account-number')}:
                                                </h5>
                                            </div>
                                            <p className="mb-1">
                                                {provider.bankDetails ? provider.bankDetails[provider.bankDetails.length - 1]?.numeroBanque : ''}
                                            </p>
                                        </ListGroup.Item>
                                        <ListGroup.Item
                                            action
                                            className="flex-column"
                                        >
                                            <div className="d-flex w-100 justify-content-between">
                                                <h5 className="mb-3">
                                                    {t('provider.swift-code')}:
                                                </h5>
                                            </div>
                                            <p className="mb-1">
                                                {provider.bankDetails ? provider.bankDetails[provider.bankDetails.length - 1]?.swift : ''}
                                            </p>
                                        </ListGroup.Item>
                                        <ListGroup.Item
                                            action
                                            className="flex-column"
                                        >
                                            <div className="d-flex w-100 justify-content-between">
                                                <h5 className="mb-3">
                                                {t('provider.om-number')}
                                                </h5>
                                            </div>
                                            <p className="mb-1">
                                                {provider.bankDetails ? provider.bankDetails[provider.bankDetails.length - 1]?.orangeMoney : ''}
                                            </p>
                                        </ListGroup.Item>
                                        <ListGroup.Item
                                            action
                                            className="flex-column"
                                        >
                                            <div className="d-flex w-100 justify-content-between">
                                                <h5 className="mb-3">
                                                {t('provider.momo-number')}
                                                </h5>
                                            </div>
                                            <p className="mb-1">
                                                {provider.bankDetails ? provider.bankDetails[provider.bankDetails.length - 1]?.mobileMoney : ''}
                                            </p>
                                        </ListGroup.Item>
                                    </ListGroup> 
                                </div>
                            </PerfectScrollbar>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Tarification</h4>
                        </div>
                        <div className="card-body">
                            {
                                provider.tarifProvider?.map((tarifProv, k) => {
                                    return <div key={k}>
                                    <h3 className='mt-4 text-primary text-center'>{tarifProv.tarif.nameFr}</h3>
                                    <div className="border-top mt-2 mb-5"></div>
                                        <div className="row my-4 justify-content-between">
                                            <div className="col-3">
                                                <span> {t('provider.effective-date')}:</span>
                                                <b className="ml-2">{tarifProv.dateEffet}</b>
                                            </div>
                                            <div className="col-3">
                                                <span> {t('provider.due-date')}:</span>
                                                <b className="ml-2">{tarifProv.dateCloture}</b>
                                            </div>
                                        </div>
                                        
                                        <div className="table-responsive">
                                            <div id="job_data" className="dataTables_wrapper ">
                                            <table
                                                className="display w-100 dataTable "
                                                id="example5"
                                                role="grid"
                                                aria-describedby="example5_info"
                                            >
                                                <thead>
                                                    <tr role="row">
                                                        <th className="sorting_asc" style={{ width: "177px" }}>
                                                        {t('provider.act-type')}
                                                        </th>
                                                        <th className="sorting" style={{ width: "177px" }}>
                                                        {t('provider.act-code')}
                                                        </th>
                                                        <th className="sorting_asc" style={{ width: "278px" }}>
                                                        {t('provider.act-label')}
                                                        </th>
                                                        <th className="sorting_asc" style={{ width: "177px" }}>
                                                        {t('provider.letter-price')}
                                                        </th>
                                                        <th className="sorting" style={{ width: "278px" }}>
                                                        {t('provider.letter-key')}
                                                        </th>
                                                        <th className="sorting" style={{ width: "46px" }}>
                                                        {t('provider.price')}
                                                        </th>
                                                    </tr>
                                                </thead>

                                                <tbody>

                                                    {
                                                        tarifsData(tarifProv.tarif).map((tarf, i) => {
                                                            return <tr className="odd" role="row" key={i} >
                                                                        <td>{tarf.typeActe}</td>
                                                                        <td>{tarf.codeActe}</td>
                                                                        <td>{tarf.nomActe}</td>
                                                                        <td>{tarf.prixActe}</td>
                                                                        <td>{tarf.lettreCle}</td>
                                                                        <td>{tarf.value}</td>
                                                                    </tr>
                                                        })
                                                    }
                                                    
                                                </tbody>
                                            </table>
                                            </div>
                                        </div>

                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            </>
    )
};

const mapStateToProps = (state) => {
    return {
        provider: state.provider.provider,
        currentUser: currentUser(state)
    };
};

export default withRouter(connect(mapStateToProps)(ProviderView));